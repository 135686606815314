/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReferIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.638 2.145c.134.182.257.376.362.552.105-.176.228-.37.362-.552.124-.17.416-.557.848-.806A2.527 2.527 0 0 1 14.527 5H16a2 2 0 0 1 2 2v2a2 2 0 0 1-1 1.732V16a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-5.268A2 2 0 0 1 2 9V7a2 2 0 0 1 2-2h1.473A2.527 2.527 0 0 1 8.79 1.339c.432.25.724.637.848.806Zm2.097.975c-.353.474-.72 1.25-.72 1.25s.855.069 1.441 0c.184-.023.342-.058.438-.114a.842.842 0 0 0-.842-1.458c-.096.055-.206.174-.317.322ZM7.544 4.37c.586.069 1.442 0 1.442 0s-.368-.776-.721-1.25c-.111-.148-.22-.267-.317-.322a.842.842 0 0 0-.842 1.458c.096.056.254.091.438.113ZM4 7h5v2H4V7Zm7 2V7h5v2h-5Zm-6 2h4v6H6a1 1 0 0 1-1-1v-5Zm6 0v6h3a1 1 0 0 0 1-1v-5h-4Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ReferIcon);
export default ForwardRef;
